export const tier3BadgeMints = [
  "8WGCMFjga1uvRnadX4zyZGNe27TqTQaQRNKt4bKyqhCG",
  "6cgB4tm2Kc4aCDE8P2Bevu3fLSEm5pbCjL1pbU5rjiLh",
  "3Rio5gUeWRXVh8CiTUGzNMUhF6haWv1fZfuTDA97DYQQ",
  "GzkrqfP3YzpwFTZ9aEZZNJCWJDuyPoy6dJ7T2x6Y6r5Q",
  "Ba6AbSGjFjB5qnBHtrCZVLMFcJDtEV7rQvt4uxvfTzCF",
  "DF4Dm7RiahxRG2SQoXRfh9zPv5dt4EdAndwDuiTQavhw",
  "HpasiYfpaq9YXuHeZgLEiDUyoBgw7YvTXVoNk1jer8Ei",
  "32GQLogJBgowSTNmSNaD3a5pB59D7G4fAoZtHWUyxhre",
  "Bpfu8LZ3srpEbd1ePpeki6dYBYMxAncba9ffztdBr8ix",
  "GKbSGw5TNBTAVSFfm1tdn2c35GaEtDWcp4KbEXr7n83Y",
  "HeZQbzaxMHGKwV2JXzGaxEt8hiUWha9589kUykVFZ6Ma",
  "CRJ6vZj9t6xFPrKKdm2VVLR3412Ba3vJ2pSXT9dCwUJ7",
  "3yJtgAe3JGqUVANh1QT5nZEPsorNnvqfbs7SdKo3NREz",
  "7XeYYVm69HqWnXXhuA7MMCeHnQbKXfzE9mre2rAV8fxw",
  "GefjJKoymn5skJpxAfE4hJYBArKYimwqZ99SsUJunM99",
  "8E33sqNBtkxf2QYvW3JLNaSKMdUJJsRN5UcbHK9j5h1B",
  "FKPWD8ZT3SieNCNXZbDY5z7BBQRASELkSK175je1miCW",
  "He7QWLfHDrfxzRypwULhumcGJDMuKBpzPZuBGtmoGu5z",
  "Hbxjz8679temRwnptArcKrS8kpwzMFoqEa51zR3KfXF2",
  "7UP1Zn3UWsXzQr6wSjCVkP4rxS2V9zDYMV2EDxuk5G32",
  "7b7W4yWYuNq3iK6wXhWDgQwHkUNfdhaidMF16EopG7yq",
  "G4TVpXbB5VzvJbN993H468ymQPk3pJBmDyz6Edn1NtEJ",
  "GjzyYYgbx1G22JKvYJweR2mDh2L5vXfDB4LyQTsamo69",
  "7fQn7dYrGA8bDN9MhojmUhPiMVvXNrjWWwZ1QYTXskr5",
  "FhB3spuhJ4iVFJQ4VTabfkHMqqxDaq43AAZW7SuqUWQz",
  "4KxuQiri2jjPYntoGeDAhmS73X5xqy7sLUE8nhNmwu5U",
  "74QhxFYDmnkcgoTeCu2qKXetPN1T7hh8Xrvk5gztrmdZ",
  "DNc737kJLdbBRiBo4o7BMXwTfdeuNBqoRpNyRcTXkn3n",
  "4aVqPDESEFRwjX1qxAekvVz8dH5nVXtU4pXUu9pAnCXi",
  "69F8hbcPgPex85QPzMqqFshwtfcnB9TZiBHp9XHH6SRt",
  "6yuPhtmVdMBPDfi81DWGJK2Donbf3Lqhn3PBQHtEkBES",
  "5EgQc9XBY5UsC7GWootQCyn5z9scY33QKta45eA2TPrB",
  "6EUbK3R1SPR8JUmTQ77uwR7yYYEmcxX1T1nnxh3TwAnT",
  "B5hPwixbTmh8tHugRHBxDTtUx4i9e36d1p6YkqpZpUpE",
  "7EuVarfgidH2pYo619SwgSsxQwpAWFGxhtbA2HkffuGT",
  "2ytGkYqhYHgd69LhNBD8zQcSXV3ddUicm9PA4wi72jD7",
  "Qa7atk99DQGjuftiPnybWzsV72C5q5wCUBb3MDykT4L",
  "B3QotJgZr9rWEWyj5mXuM7fwrs8ornHCWDAY542v4U5r",
  "APWnvfab5Dh55E5Cx4S8smMgMJKajyX3mfCkfUrwtckB",
  "CHt1VhMm5iuvKA14U4dJP2DoFFmzw1KkHeAi5Q3D1b9D",
  "6HCVmySctpnkZhYTmqNF7CktR9jqCoCMsJhPVbTov1Na",
  "FJZv9Jq25kkszCsszbwBGbKyYoDUqtLgE3vFD6L9SGGB",
  "6LoyEQwsjm7QD6STcSQyyyVgVcd3re4PECKfMvmfuoFJ",
  "6AH7P5d7dtWXyg2LVb3eea4gxt8WcXwQ4wFt3n2wRSQp",
  "6hmUG7fHU2CU7bDUHrQ86e9txww2joCuVvRn5bHDSYgE",
  "7vBJYYZAkmPeiHUUttrbUwsxj4LBrAq1TuM5VY7SQTeP",
  "GJaoSwZq2du7vuQ53NVojp7258tNuM9ZYC8nHXqhu372",
  "BHLNqNwNaA1vRC9Q82amJVHA9y4EgCjxBMKskdBPiz9U",
  "9iuZceo9qVL15NwJpqPAuYhTohWYWE2JUfhroLZHpgKb",
  "5y5tS9sJp1vXFGxrZpZAWjG694jNb4KgYjxUaaiEQSi9",
  "68PNKrMRBfa6UQSUE9wCBs6qi85FjGEsjyHHGmhPskUQ",
  "xBnoau6xSZ1UMCzPYTgAQvcTpXFnGSJgM9DuAvxRdnm",
  "EqZWX6yv3b3iM8obiGDwUhL71A7ZPmpvj2NjdRxaeyTW",
  "7pZ7Ectf8cApi8bYUHD9xNSvCw4iDfVs27kmZhHXbzD5",
  "HxL5YPxh69mKB9sxhSgAVU45euuiP6cGSuuz6CBfZyJQ",
  "2GS1J7MxMPQxbYFcNf3uU5sdCTPWKChPsmxipSnFfj1j",
  "B3xtcv5jKLgFCpFiFQPjLKdk5br26ujbANJSno6zYuBM",
  "BBLB9AyAC5sWKLzzhwfFyWUmDnYE3Be9aT7oXo9NmmyE",
  "7D8rdBF5BwNfhhZLQPqNzqkBUTCDjo3wpGoqiJxviruk",
  "7MMpgkozVkofMLfeAPx6q8g7UmtngVzH4XYVJVqgxz3e",
  "4j9A8YKxpLoeQSQAxK4JDf9YByVFdgFGRLqbqaRW9q6S",
  "HSHtUo6sWFXBW6pdW8um2SFkG36HArBSQ1vFNzdFeGbD",
  "2Rbe2cMFrNiiHQutSiyrQKK9Zfp3aTC4yFGbCeAKQVzp",
  "6tqPpWYYpGWfqHrKttUzG84Umb67rjzvmSoFGE2i3od2",
  "FX4bxzikZaraLRLpcNMkzSMcgDX5SmuJyd4fVki1WHV6",
  "2zQzjkBZS64ZCHjo7WN4FF8xCGAxrGQYqQxDbaarW5gu",
  "4zQsY7SorHhNsqWZyBisKfxfWQxVRDg1SHxnmZj6VaWy",
  "L4jpBZsKRVGiMp67sWhs1jn1QQaCpLD1us4DQDUEKPd",
  "4aTkNKKYqdP7L8ec5qbFF72vELNdiB4eXUf449ZofgtA",
  "FyNihwcSi1HWoGQnwHuFCWeKQQ7zXMgGabS9a6YjVwNt",
  "GsaMwSaPJBgo1yVB3sYRQAyoow9MjSaYrhcxEtQQMF66",
  "9AduDt1PJ9mfX3KVvUaMnNEodNJUwiHTwL5F56bZwhH6",
  "Em4NA88W9zgAmKqvC7SZFHQfwnZtvRWhWj6pG2WgE8Z1",
  "Ho7Us97EB79fpGGMhhJP7gr1stmJjEweyjGMwkL2hZ5M",
  "6BVXUuSuUAqEyu7ofakmokYt7sE7SxhgR72HDFH9WVYQ",
  "5XKXhTi7ninX3GrjAKB86YcLBP49Nc8cbURzykERjWx8",
  "2vjTrxrvPwVJwCme2VZDmoZSkDQ7P2CbuCsq5gJZdY3A",
  "F2A4uostrWXEHRVR4wFsGaEu5Y94NqEnMNK8KXrMQ98y",
  "Bk7juJM4K48PSQhFCkibGRUbnWGh16QD6mDuNDXmqgxo",
  "4ASLM4nQ2xK68AuhTDXCZZFBpcfYaBXwcLeXMiXN74wF",
  "4RfLEGr4WVRozDDSedvgMwoo69qhB6fQeK5HJtUWHUpT",
  "G6sRsP4pWdvYgAL6x32qtF1gkenG2Z33AhzwM5SBBVJ5",
  "CddE2szX3siCsjS3widsf2bLCJfnmfYNTWKvZKBa27qf",
  "D4wk24xemXWyUSya8mmGn9CpiDVxgpAkozeyHa6xmznC",
  "FNXdJQg2mzuP2n2PQVoeJKF2FKT6RgJY7GE58LZPaj6x",
  "9VTNaa2SPBeftewNTiEv4nWKpe8Rv9q2qMVYzBq8Fm2o",
  "B86yK8JG51jqaSi6xAqzCUGsHk7cXUKkaXnuFrJPvHpx",
  "BEnfFKqMsFdo2T1sGhb6vDsuKtGNCLokwzWXMGgdQkwE",
  "6h9iZqzzx61yFC3KiEBhGtf4ePiApesE5dQs2b8GNdUS",
  "CggZohZ3VvfvfWWGB4q8FSx2kXesZudv6FBtSCGbhBVf",
  "8ny2Sy8SsbF8LmbPFrVhuNocRFgzWQi1Hf8VDEzWBxak",
  "GuGRee66GnAanw6zNnUSPQ6zahSu1jMiqi8WT7dZby2T",
  "2cqZXmQmTEqz12oCkmenj5AraXq6g3CjrGRyEWHDVeZv",
  "4aFNv98wnVzBHdwsbpWRyYxfKjDHFmvq1MtLy9zxtxG3",
  "AQWSYW1aBk1zDTzTCRBixRwvbpYZjxb1TCEiPapxBC2t",
  "2rU1pnpMuhyv6dKm1sXAkcyH64qrn8eZi3p9prw4E4Bi",
  "FrFVRmbZzZvLxVLSb1ZiwRe3GWU5HP5Fq2Do3PndJMm6",
  "G5EVvg84bYMtJciYXENtz6BjPvDQBmnK6Ruc8qg2NZ57",
  "8txtpTPxJn2usMJUHx2AmV2AY717aUzqFbTt6RsAXTZ6",
  "4kH7es4zQjxeD5ee95kL4dU1m3Ls6wRZYDE3AoAJkHxb",
  "4xShDuTsHTmUgLYeaQbje1XivYHSpc9dPouqBreRHZDY",
  "E8zKhgTPDTNB5aHohXLfm1PghDxhkTfNymbn1DLvaZqP",
  "EkSyKtoYLUYHZ4VUz5GeeKzTx67kx45VDAUiVAZwDtD7",
  "8zMiRpTEHPouuc68esKfJk3sdpG4EmCWbi367YfAPdxg",
  "ARAFiSNPZk8v5SWr2PNufpiGdjZEmxwvzNhNsywJ5Miz",
  "Aa14MJgtdCXPswiMkNrn4JMqgvCMcD9SJxzd9HYq61id",
  "3zs5r6a6yvpNUXwC9kkJy4bmMBDEPTMUJQFg8o7kBJa4",
  "EQsUCSDH2zVRotPQaadGFYKeFeS9CahNFcqhoD7egHxo",
  "4A47LM6dnFEJyjdDYiMYvYpNfaJtUbc6ToQ51TpanynU",
  "BfooWg8nKA6W2fzUYQkfoGooe5ywKcDB1UqtBvLqUmAe",
  "FPr7G9jVHGVZufZ7GDKkKx178j1WJsANvjW1WLsyd6K3",
  "CRtMkvwB9vwVLPDNAAvZaADPSoN4SqgTA6tYKKBMqSBP",
  "E8hNtGqSa9MYwkaZSQd2FA3ko6LCvfE2VpnUo4xbm1X2",
  "6aruFcoGpzew9zhhSCtH5jP9ri85b43M7AkdoarAXNY9",
  "H4vP5NBK1TJwjM4uWiWvcaWF1LNNGKdZbTbYnMkYKnGD",
  "5xyV7mU333GmCFWtukpgQgCHTjuBG2gFJ8azeBQFxPKu",
  "J62FokoB1durujNRtyY1JtWHSGfQc5VJeoSgKzvBV8V4",
  "HR3PWjhhhbvWHEZgpJW5Hd3QRMRrjspKXKsYhSFrtw3u",
  "ENKpdNMrGH5LAGSm585qHwnfpFUDKkEVmeY5EKL35A8p",
  "BuHmEuEgrxM6NgJ8A4yG5WEguBS9J47Q4D7eZwfnaEwq",
  "GgSQSxD8opa7Za2ispA84eKENg1fHHCvfZXv3DcjKN8M",
  "1JGmFuLVwYejK4yr2unCJZ6gtQPDTPWrd6Qdvj2CPjN",
  "2Ptjwb2iNVAxYH8rSK3oVKvG3eLzfigMp5k9L6htA6Q9",
  "8s2tHMym7J1uoxRqhLe4ZJdwdp5zXYcT3s346xmdxqVs",
];
