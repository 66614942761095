export const tier1BadgeMints = [
  "D4nLKf5s8yaExiyMdz7jHL5CJzNeCuF519YnRaa2K81k",
  "483Xjgx8rHtrg2uUGN73R7zfv8HRXq7RmcZ5Z7pBg5cx",
  "Eenr1LU4MaQnkjxQzKuHVJcLUK9UnYotVvdCyR8GwbLF",
  "44MVmmYLMwRyqbybs6nejHcYUb1k9pVwvMSuwtMyZcVV",
  "DVCAgk6CqPsKejVw1XzHkSGfwpa4Um3TRxRc8oAHeBgC",
  "8iPKLVYTGJR2aVmJ72hWjTgkzvs4eSf3L9cL1ryLhuRJ",
  "2Ha5ZJoT72mUEDPLCxuBhGEHqtgMSZURqUGhe7bjen5x",
  "9myTjPG2vX9Y2cMewHuYqRc66Neg9PKVF3tN3TaQV72P",
  "E2XnTFi4Gqaj4SWz3HCDHmnWPDSbQh17iVk9qky5CQCN",
  "GB4oX2PzA1CmRwfpzthiT68rE8wY8zemHGk56JRHzXw7",
  "ByQXWfRkSGgVT7gAwGANBYzvq7FnYqEmzLzu7jtAwLfq",
  "EVp9E9G7x3UT4PutPpMhhDvuNGyLZh23yc2s6tfUvWFC",
  "4Gakx2JWRv3a1MuHcvJcVoR8QsJrozV7NoJjjGMJkTYy",
  "8VDQGWwHvV5DfvUNKHs8tsB5eFjMUkSXMjz26zh1dsej",
  "6ucHfv13qWz2psuPYnWfviV5Q5VAb72zshmzsBzD3kFF",
  "BWGyJH6gSp6CNRD82fBcQxVDPyZoVxP1uhVtbCvMVxr",
  "FcbpvYmMTh7KTpvVSdv8Lp1U6pDeRtkGgyZyYcw8miKj",
  "7JdA8ttWMDdxAhVkud94X7sZWqJ2gHeLapN6yNzbhXKQ",
  "5CFy4iw8fh7Szr3nUiEdi4nNiD875oBepHhvU47meBNR",
  "ENUN887rVWTcEpgS8fPQhcARJnsmmvffFCHuZYzEbbYT",
  "EGMtwAcuYukPaDS8cRJCPgVrhGbNZ9uVNssYvpkGWxxd",
  "FWZdXTFrtDirDWHqUXBozPW5nQz5tR8B9hDeNgyf6rch",
  "Eqj744EoZEw6sPyaqxBLaaUbBY2e35t3L223XVYrAue9",
  "7qqKJqhyMjjn3QDA6Zz6zm2pfUcTwgcbetwammjDw4Y2",
  "RL5qicm5F7wgczopRuNb1n9XtY8MAFF4GY9KztynVDK",
  "AHrsAMbg3Fv4QdfvYZaPJojDQy6qjb829KUahEPTxiZw",
  "Dad7KJCFLUgt7PRGZ3LqDTxzmCyzL9r5cQA3BVpHdgMK",
  "2yvx3F8XZCtRMhUPHN1UavuPAtoUGM4qhQDo3vYQEMK8",
  "BzW35KGq3k4UEp1T8MsqghFWqg6HGnztHac43HnQxpbu",
  "DHXmV8Ec6kH44SiCJSXEzd6MtSxwEJxok5G1oKa7ZEHq",
  "Hqo5qKdc8CH6dc2iox2qahq7V1NH3iLhXH8mug9scRrG",
  "BvfFLJodRxAFES4ftKTgG7Y4j1dmTRo6yHM488KpMtvM",
  "7M3HgnNeB7sgvuw9jjKTDY6NzXUXfGsrFCTVTdi3jn3r",
  "BX3Mk4VoimF9QDSeNdTySK3oD1YhsjyuhwHGBd1LjyZ4",
  "6Gaijvmd2fdMmcncFXaCLZ24keeWBbJU83uHg6CSVEZ5",
  "Aj4smN2Tzyp8zegR8xjC9JLhUaVqbqe7TCKK6GopH8XD",
  "By4nPqGvvKocNGC6wKpLyGA8TB9aKFgYvLjerYS3yUQd",
  "BRVQMkcfaprPN5X9D6bERr3VGxY6zi5KtuGfaLaoUZY8",
  "2hiBpYswHxchMhuLuSQMEpWxNZQ1EVFUyHmBTHTRHW9x",
  "6aS5Liieka8xJZ7XkDPxjDD49gAupRHcW1hsRKWgF3EP",
  "BmxxvW1ZxPEUbTC3wUPXtJumrdA6Nyiq5itzjryYmMd6",
  "GVuetDkDxjUwmRV5Mn6mum6g3DfBrEro3EZnnizkcB1Y",
  "9H7aKVZfhN6eFC5DFRd4xVnFFgiaavpHiif9ZgWPRsJ4",
  "Si75aHM4QmkvNV8nFA6Y2LCukWPXbixgmq9ogZ2BKym",
  "DQooGhBreFUfhx5QPCqnGbrapdRtgEVA4PEi7xM7SbZ6",
  "Hn4ZoSZY9rgb9mFYV9RBVe3Lpu464Q2oHhsi56B789sB",
  "5SNaKQiyaiig3NUsYDqevBhn17s6DXxjLyaTfx3Q9e7n",
  "BbT5sLcstDEHucz5FLuVodCxAfWUU7ZkUK5PUrG8aVbM",
  "8ZjgFa1GeNanFWphn9dLo9cBaQ1DQfaikJJTmVgtgopY",
  "AmE2S7uQvyAUUUdpagndZ2ijP6Jy7g3KdQtGh5nFGxZp",
  "GXdfGxi5B5Rhy55vsJRKPvMp4nusws6q1fV9pMoQqHTF",
  "8aA847FE1G9JKAhXPdGhLc5WMVVf2fdGemFbSBpUg8RQ",
  "CNrk3psUgqRNr3qWN8iY9mXW6fLZyARXx2fBmDGhpXJ7",
  "HpBx93sznn1P7C8BswXq3QsYGdXGKgaXiZMgNbkgGjcV",
  "E1nznVc2ZCJougB1GZqTMpDT9rU58pBeh6A75gDWj13P",
  "8oPaJaNgXjyY5BxKtxDBrBwxmX1zqZzTzr9HC7hX37b8",
  "4G5KhbTgyUjE4R81kS43Phzqv5BQiWUg7nJUZg2thDta",
  "Grq2ALSorz24qHRRxLZdNfnWzgVgzA8J3t2CMY3dacFP",
  "5bbHNecj9LT8DZSmfwauAuaQyT5bGtczAUcMMCAVQ1no",
  "93WWYAqx6Ky58qv6ejU24FyLvPMmsCEDuk6oL7Rvf5hV",
  "Q8xB66PH1NTAztJ62vLsPWBBARcyFnDL4jo4yz5VkkX",
];
