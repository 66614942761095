export const tier2BadgeMints = [
  "8sAXxdvra4EFXskce94chirZRgqo4b6sg6qMcGNV7WtM",
  "ABMRBJPKGP5pqyZZQCdraRkon2U6wwQCYZu3gQkLupZB",
  "EiVCgiAxZUqxu4HKCkf3vDS5Yinn9TmPkHSUaWKSMRv5",
  "4EQMLcbUs6fLFstqf8LG3Bg661V9KkijThHpMX6MFKuV",
  "2724KYEaAkfngvM1WURA7Drm1g6LiCpoWX78juEkGPoH",
  "Gg6oouKd5NbUguSdW4R5kRrSkpAtjuhLB6DG3HBu74vh",
  "JCT63H4KmszjZoJRwedzLR1B4gUMZ7JrYZ88BAd8BMTn",
  "12DhmWjyefaBJpGHbk5mxTFBM1vZUGE868LNtJj8cSUy",
  "GKJM7bsvUdz9gLGxQaiAbiwsBGQhK8xK2D4v3m9f4s1P",
  "HtkHW74HVAGQBrs6uTigpoqXFdVVVnMFMDBQf5E5CZh7",
  "CcBUL3KjiwjDiMQv1ziFZadFMQaFNtkA3FpC7weGAeRa",
  "9nLXtNPKS6sDUT4LVKPatpMgNvy9HfwLEoBXE2wCKmBc",
  "38HpFzymY5PZK2iGXDPZSDxvBqr28cqbhDottA9S9sKG",
  "CSFqAo5YLHmptjjHNqNDfVUrDM38Jr3u5S41dRcNkj4L",
  "4kg5p9VAy6WBFYNtonwGmGRdt3pDaV8RZE8eNHi8ddFq",
  "BGazEomNwFCv3gUmcNxXQCAjdRPmskibdLTZknkAWjVZ",
  "AfDyMDLCNUmDn9oM9R1GRD3vdUZRpP5tDYb12F5at3ii",
  "91JSvyfwZcJSRQdQoXs4MVVaxz5UP1qjaQt7DY1s5Eg4",
  "4g6GKLJiPPbVzGprDnqnQHfb1JZ6jDG5ERqAk8ZrtZap",
  "DALbsE1K8gJp3wS6vMKNfAsaLzE4WKZireDjEu4Nn1ar",
  "4KkXyjXtUJ4FQXqdLaUwri52gUNgCS3pTbFPjMjriXPT",
  "8TWjeSjR1TzYWzgNAt9gd8QGSjAM89gveoaKZNn7Vx1D",
  "8Vmcg7Wdn1EcYQLvaYGsj2wDyrtzWppSxrvQCuQifUNq",
  "5qaS3AAojF1rs3Ds7VF2ooC7gQHuEfHSFhBoyVMnwUvQ",
  "9dXY7aW9TsafbDCkKKHUNwQuqLDHjNWP7GUYdcWJFzeg",
  "4c9DAzEg5eC3uoCxZMLVBJtsvg9WWiCGo2pXBvjSAcTM",
  "2Qusa9d4xoXGBByp6cHgKHDqyHKHbeeBvVdM65HiWNuN",
  "Aa5HAS2GqitdX8JZzre57D4eg4BRWGw6ubKkpKsDUVDY",
  "5WkcPcPn9pwF9GwTT9UwS3X2mAFSYqxujgVsHHnuEnae",
  "CuVRsJxD2XKeAcrBbnM8HRo3CCTE6cWAaUrdETX5Sz7C",
  "36f4LdKnAUWKfnuM3V17bpkrXUnQvX5nGVnEZC5jnN4X",
  "3w8197t3MrVLaVz2awyAFfeBmJ2Cg6jf6jhpXaXrMVYb",
  "3iMMdREUNVYGAFPAvKjYmhRLR2nDEmqC4gL4rnTMzuEa",
  "D6rdBYphCN9bbp2YN8nQLGdUAsNGKbGz7CnbHgdinvCT",
  "3mwEt3FcME8chnGziTrPFiuH32ANqx3zikX2o5FMyFfM",
  "4nvXWosPKe1YEtEyqY94jnvdSqYs1HheiyoBQNQacCbN",
  "EwEwuMKm5MqMmZwEyYNMZFnuoDDbeXDqfdXFZ1B1xRjM",
  "FVe6Jkv5NUvq1T8UABYMAhjx5gnJr7K27jAeoDgASvuM",
  "AA6CQdw5Zu9Jrx5CuU5QCzA3cCqviYfvcc4n9yfMsiuu",
  "BE2RG9szRAyS3nUmkHiJn9JdTwgvDGdFizpv6bDuqaG2",
  "6RpahGbuUEHjMQwxsmt5qQ21y29ZcZQkrhARNCBvDkNn",
  "3vnDHX3a9PL25NqA66RogJb9mXihSm227oPhn6kzpejU",
  "31iWPTBupj17YN885t29k7iXYMFyLxfHHc1k6bBNrHyK",
  "Hy4H983p1Gv6rUkFa26RjsXj6dUAm2113Hk3P7LEYbB",
  "GsW3Uw3in4DQ4XSXZHMQFrZMP5ux58bUkjxecfkQpHJZ",
  "ENG7ZGaFwmj9FUCU4SegToyhem3Gu3DaTiGHQnA26hvw",
  "Gzg29t5iofRgYGkqu3Tfqk2t3Bd6TeLsV8z3uvZm1ZRX",
  "9QVnaG6uwGPjk5s9f2CMHkciEqZWTx7TTujYQd4nXsdj",
  "8M3tdsyRXqtibBNfwWCPQrcJQk8KersGdmwdLowA2USB",
  "AEn8H79HY4WnQRfbfPXSjQSey9RuAwNbTanYXDWoRQtR",
  "8vhFZxi9BDqLUCCXmFsdUCphmKCUCMrKob4nixP57gB2",
  "6KdtkEFJjfkaeaSFBZ32Ufd6QCWHJ72aNKZYos1XjYTD",
  "35pfVQhgWpr2h26cLuMzPqBa952vzp7tE9xaGNiKJ7xK",
  "7mRoZEAUn842KKtsEG5uvutepr2DLBAdg5gJFEK9DMG6",
  "EeW3tU4U9Yvva4nEoeT3A1EkPzzAvtbckD2BHRXAcD72",
  "AaEcsK9Dfptzx63MZWFo6awmTobrWHR9ZoDv9equ8yRW",
  "CgTLT1KUXGsgqXcHZXtGfwe5gpPEHiQmnasYqHZzuc4S",
  "BB9HGA2tAwkknDnq8FEFFmfmWSwPCCFzE5JU6Bkfuxgn",
  "GosG5zpAUsB97mJ8yBYCqZdgstrpkJemCZL6fKFkdvAE",
  "FSEcCNfmLNb7rc4mJjqfqskhi1LnE6BKvbFHBKq6P36h",
  "GPe5DcNq3KgGhhNWViH1pS4MqfWNfYYjAKb14DjjWVVE",
  "DzM9HVPP2fL9bgnUX8fExPuaN9VknfeNSDwjG1aqr6Ai",
];
